import React from 'react'
import { Route , Routes } from 'react-router-dom'
import Register from '../Register'
import Users from '../Users/Users'
import Success from '../Success/Success'
import ADMIN_PANEL from '../Users/Users'

function Path() {
    return (
            <Routes>
                <Route index element={<Register />} />  
                <Route path='/users_admin' element={<Users />} />
                <Route path='/success' element={<Success />} />
                <Route path='/admin_0105' element={<ADMIN_PANEL />} />
            </Routes>
    )
}

export default Path