import React, { useEffect } from 'react';
import './success.css'
import Success_image from './images/success.png'

const Success = () => {

    useEffect(() => {
        const timer = setTimeout(() => {
          window.location.href = '/';
        }, 4000);
    
        return () => clearTimeout(timer);
      }, []);

    return (
        <React.Fragment>
            <br />
            <br />
            <div class="container px-4 text-center">
                <div class="row gx-5">
                    <div class="col change_M1">
                        <div class="p-3">
                            <img 
                                src={Success_image}
                                alt=''
                                style={{
                                    marginTop:"5px"
                                }} />
                            <p style={{
                                marginTop:"-28px"
                            }}>
                                Malumotlaringiz serverga yuborildi.
                                <span style={{color:"#f43f5e"}}>
                                    Serverda texnik ishlar olib borilayotganligi sababli malumotlaringiz kechikishi mumkun. 
                                </span>
                                No qulayliklar uchun uzur!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Success;