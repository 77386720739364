import { Route, Routes } from "react-router-dom"
import "./styles/styles.scss"
import { QuestionPage } from "./pages/Main/QuestionPage/QuestionPage"
import { Main } from "./pages/Main/Main/Main"
import Path from "./components/Register/Paths/Path"
import Admin0105 from './components/Register/Users/Users'


export const App = () => {
    return <>
        <Routes>
            <Route path="/" element={<Main/>}/>
            <Route path="/home/*" element={<QuestionPage/>} />
            <Route path="/register/*" element={<Path /> } />
            <Route path="/admin0105" element={<Admin0105 />} />
        </Routes>
    </>
}
