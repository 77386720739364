import React from 'react'
import students from './students';
import { Link } from 'react-router-dom';

const Users = () => {
  
  return (
    <React.Fragment>
      <br />
      <br />
      <div className="container">
        <button className='btn btn-primary'>
          <Link to="/">
            Bosh sahifaga o`tish
          </Link>
        </button>
        <br />
        <br />
        <div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Ism</th>
                <th scope="col">Universitet nomi</th>
              </tr>
            </thead>
            <tbody>
              {students.map(item => (
                <tr key={item}>
                  <td>{item.number}</td>
                  <td>{item.name}</td>
                  <td>{item.university_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
};

export default Users;