// import axios from 'axios';
// import React, {useState} from 'react'
// import {toast, ToastContainer} from "react-toastify";
import './SignUp.css'
import React from 'react'


function SignUp() {


    const handleSubmit = (e) => {
        // Prevent the default submit and page reload
        // e.preventDefault();

        // axios
            // .post("", {
                // name: nameValue,
                // lastName: lastNameValue,
                // phone_number: phone_number,
                // university: university,
                
            // })
            // .then((response) => {
            //     console.log(response);
            //     // Handle response
            //     if(response.status = 201){
            //         toast.success("Malumotlaringiz saqlandi", {
            //             position: toast.POSITION.TOP_RIGHT
            //         });
            //         window.location.href = '/register/success'
            //     }else{
            //         toast.error("Xatolik yuz berdi", {
            //             position: toast.POSITION.TOP_RIGHT,
            //         })
            //     }
            // // );
                
                // window.location.href = '/register/success'
                // window.location.reload()
            // });
            window.location.href = '/register/success'
    };

        // const [nameValue, /setName] = useState("");
        // const [lastNameValue, setlastName] = useState("");
        // const [phone_number, setPhone_number] = useState("");
        // const [university, setUniversity] = useState("");

    return (
        <React.Fragment>
            {/* <ToastContainer /> */}
            <br />
            <div className='container'>
                <div class="container text-center">
                    <div class="row align-items-center">
                        <div class="col"></div>
                        <br />
                        <div class="col">
                            <h2>
                                Ro'yhatdan o'tish
                            </h2>
                            <br />
                            <input
                                type="text"
                                placeholder={'Ismingiz *'}
                                className="form-control input_SGN"
                                // value={nameValue}
                                // onChange={(e) => setName(e.target.value)}
                            />
                            <br />
                            <input
                                type="text"
                                placeholder={'Familiyangiz '}
                                className="form-control input_SGN"
                                // value={lastNameValue}
                                // onChange={(e) => setlastName(e.target.value)}
                            />
                            <br />
                            <input
                                type="text"
                                placeholder={'Telefon raqam *'}
                                className="form-control input_SGN"
                                // value={phone_number}
                                // onChange={(e) => setPhone_number(e.target.value)}
                            />
                            <br />
                            <input
                                type="text"
                                placeholder={'Universitet nomi *'}
                                className="form-control input_SGN"
                                // value={university}
                                // onChange={(e) => setUniversity(e.target.value)}
                            />
                            <br />
                            <button
                                className='btn-send'
                                onClick={handleSubmit}>
                                Yuborish
                            </button>
                        </div>
                        <div class="col"></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SignUp