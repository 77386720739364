export const students = [
    { "number": "GEO1215", "name": "ABDULLAYEV AZAT", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1950",  "name": "AMANOV JAMSHID", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1997", "name": "AXNAZAROVA SALTANAT", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1908", "name": "AYTMURATOV SAPARGELDI", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1360", "name": "BAYMURATOV MELS", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1513", "name": "BAYNAZAROV TOKTARBAY", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1679", "name": "DJUMANAZAROVA GULZIRA", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1871", "name": "GENJEBAEV ILHAMBAY", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1789", "name": "JOLDASBAYEV AYDOS", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1867", "name": "JOLDASBAYEV RAMAZAN", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1040", "name": "KALBAYEV ALLAYAR", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1724", "name": "KARATAYEVA SHAXIDA", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1320", "name": "KOSHIMURATOV DAO'RANBEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1997", "name": "KUDAYBERGENOV MURATBAY", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1856", "name": "QALBAYEV YERSULTAN", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1876", "name": "RAXIMBAYEV QODIR", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1710", "name": "SAGIDULLAYEV ALISHER", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1908", "name": "SEYTPANOV IKRAMBEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1463", "name": "SHIRLIYEV YAGMURGELDI", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1710", "name": "UMBETALIYEVA AZIZA", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1087", "name": "YELMURATOV BEKMURAT", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1549", "name": "YESHANOV TIMURJAN", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1272", "name": "ABDAMBETOVA GULZAR", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1211", "name": "AMANGELDIYEV ULAN", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1039", "name": "ARAZMURADOV MIRAT", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1815", "name": "BAYNIYAZOV RASHID", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1307", "name": "BERDIMURATOV TIMUR", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1286", "name": "DAUYEKOV ISABEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1519", "name": "ESHMURODOVA SHAROFAT", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1979", "name": "KALBAYEVA AMANGUL", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1919", "name": "KARIMOVA DILBAR ", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1507", "name": "KASHKINBAYEV SULTANBEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1706", "name": "KOSHIYEVA ARAY", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1821", "name": "MADREIMOV DAULETBAY", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1184", "name": "NIYETBAYEV HAYTBAY", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1825", "name": "NURATDINOVA NARGIZA", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1058", "name": "OMIRBEKOV ISMAYIL", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1484", "name": "QIRIQBAYEV XOJAXMET", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1601", "name": "QOSBERGENOVA DINORA", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1597", "name": "ROZUKULOV RASUL", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1875", "name": "SARSENBAEV ALISHER", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1193", "name": "SBANAZAROV DAVID", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1235", "name": "URAKBAYEVA NODIRA", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1415", "name": "USENBAYEV BAXTIYAR", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1742", "name": "UTEGENOV SALAMAT", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1935", "name": "XOJAMETOV JANIBEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1650", "name": "XOJAMURATOVA MUNISA", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1713", "name": "URAZALIYEVA SHAIRA", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1542", "name": "ABDULLAYEV ZAFARBEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1332", "name": "AVAZOV ABDUHALIM", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1535", "name": "BAZARBAYEVA KUMUSH", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1416", "name": "DERYAYEVA OGULNUR", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1427", "name": "DIYOROV DEHRUZ", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1119", "name": "ESHIMOV ABDULAZIZ", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1425", "name": "ESHMATOV JASURBEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1116", "name": "ESHNAZAROV SARDOR", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1294", "name": "G'AFFAROVA SHAMSIRO'Y", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1853", "name": "HAZRATOVA IRODAXON", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1198", "name": "HUBBIMOV QUVONDIQ", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1927", "name": "ISKANDAROV AHMADJON", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1879", "name": "ISKANDAROVA DILNURA", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1266", "name": "JUMABOYEV NE'MAT", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1236", "name": "JUMABOYEVA SURAYYO", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1369", "name": "JUMANAZAROV SHOXRUX", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1201", "name": "KARIMXO'JAYEV NODIRBEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1022", "name": "MATMURODOVA SOHIBA", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1101", "name": "MAXMANAZAROVA CHAROS", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1098", "name": "NABATULLAYEV ILHOMBEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1650", "name": "NORBOYEV ODILJON", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1411", "name": "NURMATOVA OGULTAGAN", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1897", "name": "O'KTAMBOYEVA CHAROS", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1270", "name": "O'ROZBOYEVA KUMUSH", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1075", "name": "ORUNBAYEVA ZAMIRA", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1855", "name": "PRIMBETOVA KAMILA", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1745", "name": "QALANDAROVA RAYHON", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1501", "name": "QOZOQOV SULTONBEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1077", "name": "RAJAPBAYEV DILMURAT", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1372", "name": "RAJAPBOYEV OG'ABEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1337", "name": "RAMETOV NURSULTON", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1301", "name": "RAMONOV ELBEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1885", "name": "SAPARMURATOV AZAMAT", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1945", "name": "SERIKBAYEVA BAXTIGUL", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1063", "name": "SHARIPBOYEVA ELINUR", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1974", "name": "SHARIPBOYEVA ELINUR", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1496", "name": "SUNNATOVA SHAHNOZA", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1960", "name": "TO'YEV SA'DULLO", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1166", "name": "ABDIMURATOV IZZET", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1148", "name": "ABDIMURATOV TURSÍNBAY", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1676", "name": "ABUTOV NURPOLAT", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1340", "name": "AUEZOV ALISHER ", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1792", "name": "DUYSENOVA TURDIGUL", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1595", "name": "GENJEBAYEV RINAT", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1591", "name": "JAQSÍMURATOV ARMAN", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1852", "name": "JIENBAEV KURBANBAY", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1533", "name": "KANIYAZOVA SHAXSANEM", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1575", "name": "KO'SHEKBAEV ANVARBEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1823", "name": "OMIRBAYEV BERDIYAR", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1511", "name": "OMIRZAKOV ABDISADIK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1389", "name": "ORINBASAROV NURLAN", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1412", "name": "OSPANOVA MALIKA", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1088", "name": "PIRNAZAROV ERNAZAR", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1332", "name": "QANNAZAROV IKRAM", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1673", "name": "QDÍRBAEV MÍRZABAY", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1916", "name": "QURBANIYAZOV MUSABEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1344", "name": "SARSENBAYEV ASADBEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1189", "name": "SHERIPBAYEV KURAL", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1791", "name": "TANIRBERGENOV RUSLANBEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1334", "name": "TOREJANOV ASADBEK", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1197", "name": "TURDI'BAEV KUWANI'SHBAY‘", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1333", "name": "TURDI'MURATOV QUWAT", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1540", "name": "YULDASHOVA AYSANEM", "university_name": "AJINIYOZ NOMIDAGI NUKUS DAVLAT PEDAGOGIKA INSTITUTI" },
    { "number": "GEO1445", "name": "ALLABERGANOV ASRORBEK", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1706", "name": "ALLAYOROV SOXIB", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1923", "name": "AZIMOVA GULRUX", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1602", "name": "BERDIYEV BEHZOD", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1004", "name": "CHO'TBOYEVA O'GILOY", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1850", "name": "ELMURODOVA MARJONA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1763", "name": "ERGASHEVA NARGIZA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1750", "name": "ERMATOV INOMJON", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1350", "name": "ISMAILOVA ZARINABONU", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1202", "name": "ISTAMOVA MALIKA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1584", "name": "JALGASBAYEV NURSULTAN", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1818", "name": "KARIMOV XASAN", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1558", "name": "KARIMOVA LAYLO", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1424", "name": "KARIMOVA MADINA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1332", "name": "KUBAYEV DIYORBEK", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1505", "name": "MALKAJDAROV JANIBEK", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1680", "name": "MANSUROVA NARGIZA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1844", "name": "MUSTOFAYEV XUDOYBERDI", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1232", "name": "NASIMOVA SABINA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1442", "name": "NEMATOV FARRUX", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1058", "name": "NURMATOV URALBOY", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1826", "name": "O'KTAMOVA SARVINOZ", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1981", "name": "OSTONOVA DILDORA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1874", "name": "QAXXOROVA CHAROS", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1784", "name": "QUDRATOV SUNNATILLO", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1672", "name": "RAVSHANOVA MAHLIYO", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1398", "name": "RAXMONOV SHOBEK", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1185", "name": "SAYFULLOYEVA MASHHURA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1321", "name": "SEYITOVA YULDUZ", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1939", "name": "SOBIROV HASAN ", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1039", "name": "SUYUNOVA ELMIRA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1770", "name": "ZOKIROV BOBUR", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1971", "name": "ABDUG'ANIYEVA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1393", "name": "ABDUJALILOVA ODINA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1178", "name": "ANNAMAHMATOV RAYIM", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1000", "name": "BOBOJONOV NURTOSH", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1995", "name": "BOLTAYEVA MAHLIYO", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1452", "name": "DJULBEKOV ISOMIDDIN", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1777", "name": "FAYZIDDINOV UMIDJON", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1061", "name": "JALILOV XUMOYUN", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1321", "name": "KATTABOYEVA AZIZA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1606", "name": "MAMATQOSIMOVA FARANGIZ", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1977", "name": "MIRZAYEV DAVLATJON", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1814", "name": "MURODOV JURABEK", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1880", "name": "MUZAFFAROV OZODBEK", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1838", "name": "NASRITDINOV ADXAM", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1960", "name": "NOMOZOVA MARG'IYONA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1765", "name": "NURIDDINOVA NARGIZA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1649", "name": "OCHILOV OYBEK", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1083", "name": "ORIPOV JASURBEK", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1372", "name": "PIRMAMATOVA MARJONA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1435", "name": "PRIMOVA OZODA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1982", "name": "SATTOROVA BARNO", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1829", "name": "SAYIMOVA XONIGUL", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1806", "name": "SHONAZAROVA IQBOL", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1130", "name": "SHONAZAROVA TO'LG'ONOY", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1613", "name": "SOLIYEVA DILNOZA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1445", "name": "TURDIYEV ULUG'BEK", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1634", "name": "TURSUNKULOV XURSHID", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1882", "name": "UMIROVA DILDORA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO1091", "name": "ZOKIROVA RAYHONA", "university_name": "Navoiy Davlat Pedagogika Instituti" },
    { "number": "GEO0105", "name":"GEO-ADMIN" }
];
export default students