import React from 'react'
import SignUp from './SignUp/SignUp';
import LogIn from './LogIn/LogIn';

function Register() {
    return (
        <React.Fragment>
            <div class="container px-4 text-center">
                <div class="row gx-5">
                    <div class="col">
                        <SignUp />
                    </div>
                    <div class="col">
                        <LogIn />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Register;