import React, { useState } from 'react';
import { students } from '../Users/students';
import {toast, ToastContainer} from "react-toastify";
import '../SignUp/SignUp.css'

const Login = () => {
  toast.warning("Ism Familiyangizni barcha harflarini katta harflar bilan yozing", {
    position: toast.POSITION.TOP_RIGHT
  })

  const [id, setId] = useState('');
  const [name, setName] = useState('');

  const handleLogin = () => {
    const student = students.find(student => student.number === id && student.name === name);
    if (student) {
      toast.success("Id va Ism topildi.", {
        position: toast.POSITION.TOP_RIGHT,
    })
    } else {
        toast.error("Id yoki Ism noto'g'ri", {
        position: toast.POSITION.TOP_RIGHT,
    })
    }
  };

  const handleLogin1 = () => {
    const student = students.find(student => student.number === id && student.name === name);
    if (student) {
      toast.success("Admin", {
        position: toast.POSITION.TOP_RIGHT,
    })
    window.location.href = "/admin0105"
    } else {
        toast.error("Admin Panelga o`tishga harakat qilindi: No to`g`ri urunish haqidagi habar tegishli joyga yuborildi.", {
        position: toast.POSITION.TOP_RIGHT,
    })
    }
  };

  const doubleClick = () => {
    handleLogin()
    handleLogin1()
  }

  return (
    <div>
      <br />
      <ToastContainer />
      <h2>Kirish</h2>
      <br />
      <input
        type="text"
        placeholder="Id GEO1234"
        value={id}
        className="form-control input_SGN"
        onChange={e => setId(e.target.value)}
      />
      <br />
      <input
        type="text"
        placeholder="Ism familiyangiz (Katta harflar bilan)"
        value={name}
        className="form-control input_SGN"
        onChange={e => setName(e.target.value)}
      />
      <br />
      <button 
        onClick={doubleClick}
        className='btn-send_logIn'>
          Yuborish
          </button>
    </div>
  );
};

export default Login;
